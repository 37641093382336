<template>
  <div
    :class="theme"
    class="branding"
  >
    <div class="branding__wrapper">
      <nuxt-link
        :to="indexPage"
        class="branding__logo"
        tabindex="-1"
        title="Zur Startseite"
      >
        <svg-icon
          :image="logo"
          class="icon icon--logo"
        />
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/03-generic/images/logo.svg';

export default {
  name: 'Branding',
  props: {
    theme: { type: String, default: '' },
  },
  data() {
    return {
      logo,
      indexPage: {
        name: 'index',
      },
    };
  },
};
</script>

<style lang="scss">
@import 'assets/base';

.branding {
  &__wrapper {
    height: 100%;
  }
  &__logo {
    .icon--logo {
      width: 153px;
    }
  }
}
</style>
<style lang="scss" scoped>
@import 'assets/base';

.branding {
  background-color: $color-primary;
  height: 84px;
  padding: 0 12px;
  top: 0;
  left: 0;
  right: 0;

  &--dark {
    background-color: color(grey, 10000);
    position: static;
    margin-bottom: $grid-base;
  }

  &--spaceless {
    padding: 0;
  }

  &--header {
    height: 84px;
  }

  @include mq($mq-large) {
    padding: #{$grid-medium-gutter * 0.5} $grid-medium-gutter;

    &--spaceless {
      padding: 0;
    }
  }

  &__wrapper {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: $grid-max;
  }
  &__logo {
    &:link,
    &:visited,
    &:hover,
    &:active {
      color: color(white);
      text-decoration: none;
    }
  }

  &__logo {
    color: $color-secondary;
    margin-top: 14px;
    @include mq($mq-medium) {
      margin-top: 10px;
    }
  }
}
</style>
